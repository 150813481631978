import React from "react"
import { css } from "@emotion/react"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import SEO from "../components/seo"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const Data = () => {
  return (
    <Layout>
      <SEO title="Data"></SEO>
      <div
        css={css`
          text-align: center;
          margin-top: 30px;
          margin-bottom: 50px;
        `}
      >
        <h1
          css={css`
            margin-bottom: 0;
          `}
        >
          Data Shop
        </h1>
      </div>

      <div
        css={css`
          max-width: 800px;
          margin: 0 auto;
          p {
            font-size: 20px;
          }
        `}
      >
        <p>
          Get some useful data, and help keep our servers running at the same
          time.
        </p>
        <h2>
          <OutboundLink href="https://gum.co/ueDHw" target="_blank">
            OPML File ($5){" "}
          </OutboundLink>
        </h2>
        <p>
          OPML file with all the blogs on Blog Surf (blogsurf.io). Right now
          these are all software engineering blogs. You can import this into
          your RSS reader to easily follow the blogs you're interested in 🪄.
        </p>

        <h2>
          <OutboundLink href="https://gum.co/mRcjs" target="_blank">
            Airtable Data ($10)
          </OutboundLink>
        </h2>
        <p>
          Airtable base with all of the blogs on Blog Surf (blogsurf.io). Right
          now these are all software engineering blogs. Do advanced searches and
          filtering, export to csv to do some data processing, the possibilities
          are endless ✨.
        </p>
      </div>
    </Layout>
  )
}

export default Data
